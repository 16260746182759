/**
 * Формирует единое имя класса на основе модуля стиля, списка имен классов и списка внешних стилей.
 * @category Utils
 *
 * @example
 * const containerClassName = buildClassNames(styles, [
 *    'container',
 *    isOpen && 'container--open',
 * ], [ 'h-mb-10' ]);
 *
 * const buttonClassName = buildClassNames(styles, [
 *    'button',
 *    `button--${buttonType}`,
 * ]);
 */
export function buildClassNames(styles: { [key: string]: string }, classNames: any[], appendExternalClassNames: any[] = []): string {
    return classNames
        .filter(className => className && styles[className])
        .map(className => styles[className])
        .concat(appendExternalClassNames.filter(className => className))
        .join(' ');
}

/**
 * TODO
 * @category Utils
 */
export function mergeClassNames(classNames: any[]): string {
    return classNames
        .filter(className => className)
        .join(' ');
}

export const cnx = mergeClassNames;